import payload_plugin_DO35zVa98w from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase@10.14.1_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_DSkoLSuZOb from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hvH6KjJLcZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_a3Mvt8qMqX from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_efL2oer7Bl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_vite@5.4.11_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_C0kzND61NP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GGgUDHo3YA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_YqPt59LYgo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kV5qEdkzPd from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/opt/buildhome/repo/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_iQdqnfJdJe from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_IG9M9YW0Of from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase@10.14.1_vuefire@3.2.0/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/opt/buildhome/repo/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/opt/buildhome/repo/.nuxt/vuefire-plugin.mjs";
import titles_dcEvoA2YRz from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.13.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_7aMVZen7aI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_vite@5.4.11_vue@3.5.12/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_crz07aLRxV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_vite@5.4.11_vue@3.5.12/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_mrYH8375SM from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_A40UjEhIJB from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_aiHInc01Na from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_eNelkHx7DD from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_67cDs2Hjwo from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.7.5_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_9edAPx0Z6u from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_hpnMeFlv27 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_wZzOISZEJT from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import paddleJS_client_6OSkSWlVfm from "/opt/buildhome/repo/plugins/paddleJS.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
import defaults_be63JywLnt from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_h3@1.13.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  payload_plugin_DO35zVa98w,
  revive_payload_client_DSkoLSuZOb,
  unhead_hvH6KjJLcZ,
  router_a3Mvt8qMqX,
  _0_siteConfig_efL2oer7Bl,
  payload_client_C0kzND61NP,
  navigation_repaint_client_GGgUDHo3YA,
  check_outdated_build_client_YqPt59LYgo,
  chunk_reload_client_kV5qEdkzPd,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iQdqnfJdJe,
  plugin_client_IG9M9YW0Of,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  titles_dcEvoA2YRz,
  siteConfig_7aMVZen7aI,
  inferSeoMetaPlugin_crz07aLRxV,
  slideovers_mrYH8375SM,
  modals_A40UjEhIJB,
  colors_aiHInc01Na,
  plugin_client_eNelkHx7DD,
  plugin_67cDs2Hjwo,
  scrollbars_client_9edAPx0Z6u,
  presets_hpnMeFlv27,
  variables_wZzOISZEJT,
  paddleJS_client_6OSkSWlVfm,
  sentry_client_shVUlIjFLk,
  defaults_be63JywLnt
]