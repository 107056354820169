import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_eslint@9.14.0_typescript@5.6.3_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_vite@5.4.11_vue@3.5.12/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}