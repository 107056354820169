<script setup lang="ts">
import * as Sentry from "@sentry/vue";

const colorMode = useColorMode();

const color = computed(() =>
  colorMode.value === "dark" ? "#111827" : "white"
);

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "en",
  },
});

useSeoMeta({
  titleTemplate: "%s - Teslemetry",
  //ogImage: "https://teslemetry.com/social-card.png",
  //twitterImage: "https://teslemetry.com/social-card.png",
  //twitterCard: "summary_large_image",
});

if (process.client) {
  const user = useCurrentUser();
  const router = useRouter();
  const route = useRoute();
  const { $paddle } = useNuxtApp();

  onMounted(() => {
    watch(user, (user, prevUser) => {
      if (user) {
        // Login
        Sentry.setUser({
          id: user.uid,
          username: user.displayName,
          email: user.email,
        });
      } else if (prevUser) {
        // Logout
        if (!["/", "/delete"].includes(route.path)) {
          router.push("/");
        }
        $paddle.Update({ pwCustomer: null });
        Sentry.setUser(null);
      }
    });
  });
}
</script>

<template>
  <div>
    <NuxtLoadingIndicator />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <UNotifications />
  </div>
</template>
