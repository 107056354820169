// https://www.lichter.io/articles/nuxt3-sentry-recipe/
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  async setup(nuxtApp) {
    const {
      public: { sentry },
    } = useRuntimeConfig();

    //const tracing = Sentry.browserTracingIntegration({ router });
    const replay = Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    });
    const feedback = Sentry.feedbackIntegration({
      autoInject: false,
    });

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: import.meta.env.NODE_ENV,
      integrations: [replay, feedback], //tracing
      enabled: !import.meta.env.DEV,
      tracesSampleRate: sentry.traces_sample_rate as number,
      replaysSessionSampleRate: sentry.replay_sample_rate as number,
      replaysOnErrorSampleRate: sentry.error_replay_sample_rate as number,
    });

    return { provide: { feedback } };
  },
});
